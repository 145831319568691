import Vue from "vue";
import Vuex from "vuex";

import StoreAlert from "./store-alert/store-alert.js";
import StoreElement from "./store-element/store-element.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ...StoreAlert.state,
    ...StoreElement.state,
    removingBg: false,
    catalogIdentifier: "b2b-sua-logo-1",
    corporativeSalesIdentifiers: [
      "b2b-sua-logo-1",
      "b2b-sua-logo-2",
      "b2b-sua-logo-3",
    ],
  },
  mutations: {
    ...StoreAlert.mutations,
    ...StoreElement.mutations,
    removingBg(state, removing = false) {
      state.removingBg = removing;
    },
    SET_IDENTIFIER(state, catalogIdentifier) {
      state.catalogIdentifier = catalogIdentifier;
    },
  },
  actions: {
    ...StoreAlert.actions,
    ...StoreElement.actions,
    removingBg({ commit }, removing) {
      commit("removingBg", removing);
    },
    updateIdentifier({ commit }, catalogIdentifier) {
      commit("SET_IDENTIFIER", catalogIdentifier);
    },
  },
  getters: {
    ...StoreAlert.getters,
    ...StoreElement.getters,
    isCorporativeSales: (state) => {
      return state.corporativeSalesIdentifiers.includes(
        state.catalogIdentifier
      );
    },
  },
});
