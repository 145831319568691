<template>
  <lite-component
    v-if="customization"
    :material="customization.material"
    :customization="customization"
    :settings="$settings"
  />
</template>

<script>
import LiteComponent from "@/components/lite-component/LiteComponent.vue";

import { getSimilarGroup } from "@/api/catalog-api/similar-group-api/similarGroup.api.js";
import { getCustomization } from "@/api/catalog-api/design-customization-api/designCustomization.api.js";
import { getLineItemHash } from "@/api/catalog-api/line-item-design-customization-api/lineItemDesignCustomization.api.js";
import { getInitialGroup } from "@/api/catalog-api/initials-api/initials.api.js";

const { version } = require("../package.json");

export default {
  name: "engine",
  components: { LiteComponent },
  data() {
    return { customization: null };
  },
  methods: {
    async updateMaterial() {
      // Enable edit mode
      const { identifier, store, mockups } = this.$settings.product;
      let { material } = this.$settings.product;
      let editCustomization;

      if (this.$settings.editMode) {
        const { image_src } = this.$settings.editMode;
        if (image_src) {
          await getLineItemHash({ image_src })
            .catch(() => (editCustomization = null))
            .then(
              (res) => (editCustomization = res.engine_info_json.customization)
            );
        }
      }

      if (!material) {
        this.$store.dispatch("addAlert", "missing-material");
        material = this.$settings.boot.material;
      } else this.$store.dispatch("removeAlert", "missing-material");

      getCustomization({ identifier, material, store }).then(
        async (customization) => {
          customization.timestamp = version;
          customization.material = material;
          customization.store = store;
          customization.mockups = mockups || [];

          if (store) {
            const initials = customization.elements
              .map((e) => e?.components?.map((c) => c.initialGroup))
              .flat()
              .filter((e) => !!e);

            await Promise.all(
              initials.map((name) => getInitialGroup({ name, store }))
            ).then((e) => {
              customization.elements.forEach((el) =>
                el?.components?.forEach((c) =>
                  c.images?.forEach(
                    (i) =>
                      (i.disabled = c.initialGroup
                        ? !e
                            .find((g) => g.name === c.initialGroup)
                            ?.initials.includes(i.parameterCode)
                        : false)
                  )
                )
              );
            });
          }

          this.customization = this.updateCustomization(customization);

          if (editCustomization) {
            this.customization = this.editMode(editCustomization);
          }

          if (
            [
              "foto-do-meu-pet",
              "meus-pets-foto",
              "meus-pets-foto-1",
              "meus-pets-foto-2",
              "meus-pets-foto-4",
              "meus-pets-foto-5",
              "kids-picture-1",
              "kids-picture-2",
              "kids-picture-3",
              "kids-picture-4",
              "kids-picture-5",
              "test-dd",
              "test-dd-2",
              "test-dd-3",
              "test-dd-4",
              "test-dd-5",
              "test-dd-6",
              "pet-face-extraction-1",
              "pet-face-extraction-2",
              "pet-face-extraction-3",
              "pet-face-extraction-4",
              "pet-face-extraction-5",
              "pet-face-extraction-6",
              "b2b-sua-logo-1",
              "b2b-sua-logo-2",
              "b2b-sua-logo-3",
            ].includes(this.customization.identifier)
          ) {
            this.$store.dispatch("enableControls");
          }

          const href = window.location.href;
          const url = new URL(href);
          const mode = url.searchParams.get("mode") || "normal";

          if (
            mode === "pattern" ||
            [
              "test-dd",
              "test-dd-2",
              "test-dd-3",
              "test-dd-4",
              "test-dd-5",
              "test-dd-6",
              "test-dd-11",
              "test-dd-22",
              "test-dd-33",
              "test-dd-44",
              "test-dd-55",
              "test-dd-66",
              "pet-face-extraction",
              "pet-face-extraction-1",
              "pet-face-extraction-2",
              "pet-face-extraction-3",
              "pet-face-extraction-4",
              "pet-face-extraction-5",
              "pet-face-extraction-6",
            ].includes(this.customization.identifier)
          ) {
            this.$store.dispatch("activePatternMode");
            if (
              this.customization.identifier.match("pet") ||
              this.customization.identifier === "test-dd"
            ) {
              this.$store.dispatch(
                "setPatternEngineName",
                "pet-face-extraction"
              );
            }
          }

          const haveSomeGroup = customization?.elements?.find(
            (el) => el.groups?.length
          );

          if (haveSomeGroup) {
            const haveGroups = (el, similar_groups) => {
              if (!el?.groups?.length) return true;

              return !!el.groups.find((gpId) => {
                const group = similar_groups.find(
                  (group) => group?.identifier === gpId
                );

                if (
                  !group &&
                  !!customization.elements.find(
                    (ell) =>
                      ell.label.values.default !== el.label.values.default &&
                      ell.id !== el.id
                  )
                ) {
                  return true;
                }

                const matchMaterial = !!group.devices.find(
                  (device) => device === material
                );
                return matchMaterial;
              });
            };
            getSimilarGroup().then(({ similar_groups }) => {
              customization.elements = customization.elements.filter((el) => {
                return !!haveGroups(el, similar_groups);
              });
            });
          }
        }
      );
    },
    editMode(customization) {
      this.customization.elements.forEach((element) => {
        const tElement = customization.elements.find(
          (el) => el.identifier === element.identifier
        );
        if (!tElement) return;
        if (element.jsClass === "ElementImageModel") {
          element.components.forEach((comp) => {
            const co = tElement.components.find((c) => c.id === comp.id);
            if (!co) comp.images.forEach((i) => (i.show = false));
            else {
              const tImage = co.images.find((i) => i.active && i.show);
              comp.images.forEach((i) => {
                i.active = i.id === tImage.id;
                i.show = i.active;
              });
            }
          });
        } else if (element.jsClass === "ElementTextModel") {
          element.value = tElement.value;
          // element.color = tElement.color;
          element.components = tElement.components;
        }
      });
      return this.customization;
    },
    updateCustomization(customization) {
      if (
        !this.customization ||
        this.customization.identifier !== customization.identifier
      )
        return customization;
      customization.elements.forEach((element) => {
        const temp = this.customization.elements.find(
          (el) => el.identifier === element.identifier
        );
        if (!temp) this.notify(this.customization, customization);
        else {
          if (element.jsClass === "ElementImageModel") {
            element.components = temp.components;
          } else if (element.jsClass === "ElementTextModel") {
            element.value = temp.value;
            // element.color = temp.color;
            element.components = temp.components;
          }
        }
      });
      return customization;
    },
    notify(o, n) {
      this.$notifyError("Fix customization elements", this, {
        old: {
          identifier: o.identifier,
          material: o.material,
        },
        new: {
          identifier: n.identifier,
          material: n.material,
        },
      });
    },
  },
  mounted() {
    this.updateMaterial();
  },
};
</script>
